#community-top_container {
    display: flex;
    padding: 8px 40px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: rgba(0, 0, 41, 0.50);
    backdrop-filter: blur(32px);
}

.community_user {
    display: flex;
    /* width: 343px; */
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.community_userImg img {
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    border-radius: 80px;
}

.community_userInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
}

.community_userInfo_name {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    color: #FFF;

    -webkit-text-stroke-width: 0.8px;
    -webkit-text-stroke-color: #FFF;
    font-size: 32px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 2.56px;
}

.community_userInfo_status {
    display: flex;
    align-items: center;
    gap: 12px;

}

.community_userInfo_status span {
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: 26px;
}

.community_user_searchMsg {
    display: flex;
    align-items: center;
    gap: 40px;
}

.community_user_search {
    display: flex;
    width: 296px;
    align-items: center;
    justify-content: right;
}

.community_user_message {
    width: 40px;
    height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.community_user_message img {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
}

.community_user_message span {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-flex;
    height: 16px;
    padding: 3px 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #FF2828;
    -webkit-text-stroke-width: 0.4px;
    -webkit-text-stroke-color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.48px;
}


.community-title {

    color: #FFF;
    -webkit-text-stroke-width: 0.8px;
    -webkit-text-stroke-color: #FFF;
    font-size: 32px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 2.56px;
}


.community-icon {
    display: flex;
    width: 120px;
    justify-content: space-between;
    align-items: center;
}

.notification-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    position: relative;
}

.notification-tab__item__content {
    display: flex;
    flex-direction: column;
}

.notification-tab__item__time {
    font-size: 10px;
    color: rgba(255, 255, 255, 0.7);
    display: block;
}

.notification-bar span {
    display: inline-flex;
    height: 16px;
    padding: 3px 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    color: #FFF;
    -webkit-text-stroke-width: 0.4px;
    -webkit-text-stroke-color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.48px;
    border-radius: 50%;
    top: 0;
    right: 0;
    position: absolute;
}

.notification-icon {
    width: 24px;
    height: 32px;
    flex-shrink: 0;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 32px;
    object-fit: cover;
}

.notification-icon_active {
    width: 100%;
    height: 32px;
    flex-shrink: 0;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 32px;
    object-fit: cover;

}

/* recomm */

.recommentation_collab_status,
.recommentation_collab_status_false {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.recommentation_collab_status {
    background-color: #00BC42;
}

.recommentation_collab_status_false {
    background-color: #FF9A28;
    ;
}

.socialIcon_linked,
.socialIcon_unlinked {
    width: 32px;
    height: 32px;
    flex-shrink: 0;

}

.socialIcon_linked {
    cursor: pointer;
}

.socialIcon_unlinked {
    filter: grayscale(100%);
    opacity: 0.6;
}

.userCard_information {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 16px;
}

.community_container {
    position: relative;
    width: 100%;

}


/* Profilecard */

.community_search_type {
    display: flex;
    align-self: stretch;
    padding: 16px 40px 24px 40px;

    gap: 8px
}

.community_search_type_backbutton {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(175, 175, 175, 0.5);
    background: rgba(255, 255, 255, 0.08);
    border-radius: 16px;
    padding: 2px;
}

.community_search_type_backbutton img {
    width: 32px;
    height: 32px;
    transform: rotate(90deg);
    cursor: pointer;
}

.community_search_type_selecter {
    display: flex;
    flex: 1;
    border: 1px solid rgba(175, 175, 175, 0.5);
    background: rgba(255, 255, 255, 0.08);
    border-radius: 16px;
}

.community_search_type_item {
    display: flex;
    flex: 1;
    height: 68px;
    padding: 16px 0px;
    align-items: center;
    justify-content: center;
    gap: 24px;
    flex-shrink: 0;
    border-radius: 16px;
    -webkit-text-stroke-width: 0.8px;
    -webkit-text-stroke-color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 1.92px;

}

.selected_Type {
    background: rgba(71, 45, 219, 0.50);
    backdrop-filter: blur(8px);

}

.community_search_type_item span {
    flex: 1;
    display: flex;
    justify-content: center;
    text-align: center;
    height: 100%;
}

.search_type_separator {
    width: 2px;
    height: 36px;
    background-color: white;
    flex-shrink: 0;
    align-self: center;
}


.community_searchresult {
    display: flex;
    align-self: stretch;
    /* padding: 0px 40px; */
    align-items: center;
    height: 55vh;
    overflow-y: scroll;

    margin: 0px 40px;
    flex-wrap: wrap;
}

.community_searchresult::-webkit-scrollbar {
    display: none;
}

.community_searchr_cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    align-self: stretch;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    flex-wrap: wrap;
}

.community_searchr_cards::-webkit-scrollbar {
    display: none;
}

.profilecard_container {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    gap: 24px;
    flex: 1;
    border-radius: 16px;
    border: 1px solid rgba(175, 175, 175, 0.5);
    background: rgba(255, 255, 255, 0.08);
}

.profilecard_container_top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.profilecard_header {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
}

.profilecard_user_img {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
}

.profilecard_user_img img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
}

.profilecard_user_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* gap: 8px; */
    flex: 1;
}

.profilecard_user_name {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    /* border:1px solid rgb(7, 98, 5); */
    align-self: stretch;
    overflow: hidden;
}

.profilecard_user_name span {
    -webkit-text-stroke-width: 0.8px;
    -webkit-text-stroke-color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 1.92px;
    /* border: 1px solid rgb(59, 226, 56); */
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 38px;
    max-width: 250px;
    min-width: 250px;
}

.profilecard_user_name img {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
}

.profilecard_user_role {
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.28px;
}

.profilecard_user_status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
}

.profilecard_collab_status {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    -webkit-text-stroke-width: 0.4px;
    -webkit-text-stroke-color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.56px;
}

.profilecard_collab_open_status,
.profilecard_collab_close_status {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.profilecard_collab_open_status {
    background-color: #00BC42;
}

.profilecard_collab_close_status {
    background-color: #FF9A28;
}

.profilecard_rate_status {
    display: flex;
    align-items: center;
    gap: 8px;
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.28px;
}

.profilecard_rate_status img {
    width: 16px;
    height: 16px;
}

.profilecard_rate_status div {
    display: flex;
    align-items: center;
    gap: 2px;
}

.profilecard_container_mid {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.profilecard_container_mid_bio {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    align-self: stretch;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.28px;
    height: 3em;
}

.profilecard_container_mid_topic {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    height: 60px;
}

.profilecard_mid_topicTitle {
    -webkit-text-stroke-width: 0.4px;
    -webkit-text-stroke-color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.96px;
}

.profilecard_mid_topics {
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    overflow: hidden;
    flex-wrap: wrap;
}

.profilecard_container_mid_title {

    -webkit-text-stroke-width: 0.4px;
    -webkit-text-stroke-color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.96px;

}

.profilecard_mid_topics_item {
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 4px;
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.80);
    color: #000029;
    text-align: right;
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: #000029;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.56px;
    white-space: nowrap;


    /* display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; */
}

.searched_topic {
    color: #fff;
    border-radius: 80px;
    border: 1px solid #60C;
    background: rgba(102, 0, 204, 0.80);
}

.profilecard_container_bottom {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    margin-bottom: 0 !important;
    /* border: 1px solid red;
    bottom: 16px; */
}


.profilecard_container_profile_bottom,
.profilecard_container_message_bottom {
    display: flex;
    height: 40px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 16px;
    /* text-align: right; */
    color: #FFF;
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.64px;
    border: none;
}

.profilecard_container_profile_bottom {
    border: 1px solid #FFF;
    background: inherit;
}

.profilecard_container_profile_bottom:hover {
    border: 1px solid #48CDFF;
}

.profilecard_container_message_bottom {
    background: rgba(41, 104, 254, 0.80);
}

.profilecard_container_message_bottom:hover {
    background: rgba(100, 145, 252, 0.8);
}

.pending_connected_button {
    background: inherit;
}

.pending_connected_button:hover {
    background: none;
}

.profilecard_container_message_bottom img {
    width: 24px;
    height: 24px;
}