.notification-tab {
    display: flex;
    width: 433px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 16px;
    border: 1px solid rgba(175, 175, 175, 0.5);
    background: rgba(0, 0, 41, 0.80);
    backdrop-filter: blur(12px);
    max-height: 80vh;
    /* position: absolute;
    right: 40px; */
    color: #FFF;
}

.notification-window {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    top: 13%;
    right: -100%;
    transition: right 0.8s ease-in-out;
    z-index: 1000;
    border: 1px solid red;
    padding: 0px 40px;
}

.notification-window.show {
    right: 0;
    border: 1px solid red;
}

.notification-tab__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
}

.notification-tab__header span {
    color: #FFF;
    -webkit-text-stroke-width: 0.8px;
    -webkit-text-stroke-color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 1.92px;

}

.notification-tab__header__button {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    border: 1px solid #FFF;
    background: inherit;
    color: #FFF;
}

.notification-tab__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-self: stretch;
    overflow-y: auto;
}

.notification-tab__content::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.notification-tab__content {
    -ms-overflow-style: none;
    /* Hide scrollbar in IE & Edge */
    scrollbar-width: none;
    /* Hide scrollbar in Firefox */
}

.notification-tab__item {
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.10);
    backdrop-filter: blur(8px);
}

.notification-tab__item__head {
    display: flex;
    gap: 16px;
    align-items: center;
    align-self: stretch;
}

.notification-tab__item_footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}

.notification-tab__item_button {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 16px;
    transition: background-color 0.3s ease-in-out;
}

.notification-tab__item_button:hover {
    background: rgba(41, 104, 254, 0.2);
    border-radius: 16px;
}

.notification-tab__item_button2 {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 16px;
    background: rgba(41, 104, 254, 0.80);
}

.notification-tab__item__icon {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    aspect-ratio: 1/1;
    border-radius: 64px;
    background: #D9D9D9;
}

.notification-tab__item__icon_pic {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 64px;

}

.notification-tab__item__title {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
}

.notification-tab__item__user {
    /* color: #FFF;
    font-size: 19px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 1.52px; */

    position: relative;
    font-size: 19px;
    letter-spacing: 0.08em;
    font-weight: 800;
    color: #fff;
    text-align: left;

}

.notification-tab__item__message {
    /* color: #FFF;
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.28px; */

    flex: 1;
    width: 100%;
    position: relative;
    font-size: 14px;
    letter-spacing: 0.02em;
    font-weight: 200;
    color: #fff;
    text-align: left;
    /* display: inline-block; */

}

.notification-tab__item__top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.notification-tab__item__icon2 {
    width: 40px;
    position: relative;
    height: 40px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
}

.notification-tab__item__message2 {
    /* color: #FFF;
    -webkit-text-stroke-width: 0.4px;
    -webkit-text-stroke-color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.28px;
    flex: 1 0 0; */

    flex: 1;
    width: 100%;
    position: relative;
    font-size: 14px;
    letter-spacing: 0.02em;
    font-weight: 200;
    color: #fff;
    text-align: left;
    display: inline-block;
}

/* .community_container {
    display: flex;
    position: relative;
} */