#page-top {
    display: flex;
    height: 104px;
    padding: 32px 40px 0px 40px;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    cursor: pointer;
}
.header-page-title {

    color: #FFF;
    -webkit-text-stroke-width: 0.8px;
    -webkit-text-stroke-color: #FFF;
    font-size: 32px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 2.5px;
}

.page-icon {
    display: flex;
    width: 120px;
    justify-content: space-between;
    align-items: center;
}

.notification-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    position: relative;
}

.notification-tab__item__content {
    display: flex;
    flex-direction: column;
}

.notification-tab__item__time {
    font-size: 10px;
    color: rgba(255, 255, 255, 0.7);
    /* margin-top: 4px; */
    display: block;
}

.notification-bar span {
    display: inline-flex;
    height: 16px;
    padding: 3px 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    color: #FFF;
    -webkit-text-stroke-width: 0.4px;
    -webkit-text-stroke-color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.48px;
    background-color: red;
    border-radius: 50%;
    top: 0;
    right: 0;
    position: absolute;
}

.notification-icon {
    width: 24px;
    height: 32px;
    flex-shrink: 0;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 32px;
    object-fit: cover;
}

.notification-icon_active {
    width: 100%;
    height: 32px;
    flex-shrink: 0;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 32px;
    object-fit: cover;

}