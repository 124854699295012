#homepage-top {
    /* display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding: 10px; */


    display: flex;
    height: 104px;
    padding: 32px 40px 0px 40px;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
}

.homepage-title {
    /* display: flex;
    align-items: baseline;
    font-weight: 600;
    font-size: 1.75rem; */

    color: #FFF;
    -webkit-text-stroke-width: 0.8;
    -webkit-text-stroke-color: #FFF;
    font-size: 32px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 2.56px;
}

.home_profile-icon{
    display: flex;
    width: 120px;
    /* justify-content: space-between; */
    justify-content: right;
    align-items: center;
}
.notificationIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    position: relative;
}

.home-icon {
    width: 24px;
    height: 32px;
    flex-shrink: 0;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 32px;
    object-fit: cover;
}


#homepage-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    /* padding: 16px; */
    background-color: rgba(255, 255, 255, 0.01);
    max-width: 1272px;
    height: 80vh;
    /* height: 918px; */
    overflow-y: scroll;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.10);

}

#homepage-container::-webkit-scrollbar {
    display: none;
}

.homepage_maincontainer{
    padding-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.homepage-status {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
}

.status-text {
    display: flex;
    /* justify-content: center; */
    /* flex-wrap: wrap; */
    /* width: 65%; */
    gap: 16px;
    max-width: 776px;
    flex: 1 1 63%;
    align-items: center;
    align-self: stretch;
    /* border: 1px solid red; */
}

.status-texty {
    display: flex;
    gap: 16px;
    max-width: 776px;
    width: 100%;
    align-items: center;
    align-self: stretch;
    /* border: 1px solid red; */

}

.status-text-item {
    display: flex;
    max-width: 256px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex-shrink: 0;
    align-self: stretch;
    /* min-width: 150px; */
    /* min-height: 256px; */
    flex: "1 1 31%";
    /* border: 1px solid red; */
}

.text-item-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 24px;
    padding: 16px;
    align-self: stretch;
    border-radius: 0px 16px 16px 0px;
    border: 1px solid rgba(0, 0, 0, 0.00);
    box-shadow: -16px 0px 24px 0px rgba(0, 0, 0, 0.16) inset;
    position: relative;
    overflow: hidden;
}

.text-item-container2 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    padding: 16px;
    align-self: stretch;
    border-radius: 0px 16px 16px 0px;
    border: 1px solid rgba(0, 0, 0, 0.00);
    box-shadow: -16px 0px 24px 0px rgba(0, 0, 0, 0.16) inset;
    position: relative;
    overflow: hidden;
    height: 100%;
}

.text-item-container2:hover {
    background: linear-gradient(82deg, rgba(153, 153, 153, 0.00) 0%, rgba(255, 255, 255, 0.24) 138.57%);
}

.text-item-container:hover {
    background: linear-gradient(82deg, rgba(153, 153, 153, 0.00) 0%, rgba(255, 255, 255, 0.24) 138.57%);
}

.top-podcast-details {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
    align-self: stretch;
    overflow: hidden;
}

.itemTitle {
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: flex-end;
    color: #FFF;
    text-align: right;
    -webkit-text-stroke-width: 0.4000000059604645;
    -webkit-text-stroke-color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1px;
    z-index: 2;
}

.itemValue {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;
    align-self: stretch;
    color: #FFF;
    text-align: right;
    font-size: 80px;
    font-weight: 600;
    letter-spacing: 6.4px;
}

.item-Toppodcast {
    color: #FFF;
    -webkit-text-stroke-width: 2;
    -webkit-text-stroke-color: #FFF;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 3px;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
}


.text-item-container2 img {
    position: absolute;
    object-fit: cover;
    width: 210px;
    z-index: 1;
}

.text-item-container img {
    position: absolute;
    object-fit: cover;
    width: 210px;
    z-index: 1;
}

.img-chevron {
    left: -32px;
    top: 8px;
}

.img-listen {
    left: -48px;
    top: 24px;
}

.img-sentiment,
.img-vector {
    left: -64px;
    top: 24px;
}

.img-podcast {
    width: 266px;
    height: 266px;
    left: -90px;
    bottom: -40px;
}

.upcoming-events {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.10);
    border-radius: 20px;
    padding: 16px;
    flex: 1 1 33%;
    gap: 8px;
    max-width: 432px;
    height: 410px;
    /* flex: 1 0 0; */
    border-radius: 24px;
    border: 1px solid rgba(57, 56, 56, 0.5);
}

.upcoming-event-head {
    text-align: center;
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.92px;
}

.upcoming-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    /* height: 310px; */
    overflow-y: scroll;
    width: 100%;
}

.upcoming-content::-webkit-scrollbar {
    display: none;
}

.upcoming-content-item {
    display: flex;
    flex-direction: column;
    /* min-width: 240px; */
    padding: 16px;
    align-items: flex-start;
    align-content: flex-start;
    align-self: stretch;
    gap: 16px;
    flex-wrap: wrap;
    border-radius: 10px;
    background-color: rgba(71, 45, 219, 0.10);
    backdrop-filter: blur(8px);
    width: 100%;
}

.upcoming-event-Title {
    display: flex;
    align-self: stretch;
    color: #FFF;
    -webkit-text-stroke-width: 0.4000000059604645;
    -webkit-text-stroke-color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 1.6px;
}

.upcoming-event-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    font-size: 12px;
}

.upcoming-event-data {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.guests {
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
}

.upcoming-content-empty {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
    background-color: rgba(71, 45, 219, 0.10);
    border-radius: 10px;
    /* padding: 16px 8px; */

    padding: 24px 16px;
    margin-top: 16px;
}

.content-empty-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
}

.content-empty-avatar {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 16px;
}
.empty-avatar{
    /* width: 110px;
    height: 110px; */
    width: 130px;
    height: 130px;
}

.content-empty-text {
    align-self: stretch;
    text-align: center;
    -webkit-text-stroke-width: 0.800000011920929;
    -webkit-text-stroke-color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.28px;
}

.content-empty-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

}

.content-empty-footer Button {
    display: flex;
    height: 35px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 19px;
    border: none;
    background: linear-gradient(230deg, #00DAFC 15.12%, #0192FD 43.86%, #2959EB 66.92%, #5E0BCD 86.76%)
}

.content-empty-footer span {
    color: #FFF;
    text-align: right;
    -webkit-text-stroke-width: 0.20000000298023224;
    -webkit-text-stroke-color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.64px;
}



/* Chart */

.homepage-chart {
    display: flex;
    height: 512px;
    align-items: center;
    gap: 16px;
    /* flex-shrink: 0; */
    align-self: stretch;
    /* flex-wrap: wrap; */
    box-sizing: border-box;
}

.pie-chart-title,
.bar-chart-title {
    /* -webkit-text-stroke-width: 0.800000011920929; */
    /* -webkit-text-stroke-color: #FFF; */
    font-size: 24px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.5px;

}

.chartHead {
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
}

.bar-chart,
.pie-chart {
    display: flex;
    max-width: 612px;
    height: 512px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    min-width: 300px;
    box-sizing: border-box;
}

.PieChart {
    position: relative;
}

.chart {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
}

.chart-notation {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    align-self: stretch;
}

.chart-notation-item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.PieChart img {
    position: absolute;
    object-fit: cover;
    width: 160px;
    height: 160px;
    z-index: 1;
    top: 125px;
    left: 225px;
    transform: rotate(-31deg);

}

.pie-img-sentiment {
    position: relative;
}



/* 3.EventDetails */


#event-details-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    max-width: 784px;
    width: 60%;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.50);
    color: #fff;
    background-color: rgba(0, 0, 41, 0.80);
    backdrop-filter: blur(8px);
}

.event-details-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px;
    justify-content: center;
    border: none;
}

.event-details-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
}

.event-head-title {
    -webkit-text-stroke-width: 0.8px;
    -webkit-text-stroke-color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 1.92px;
}

.event-details-head button {
    background-color: inherit;
    color: inherit;
    border: none;
}

.event-content {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: center;
    /* align-items: flex-start; */
    align-items: center;
}

.event-title {
    max-width: 364px;
    flex: 1 1 48%;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 2.4px;
}

.event-info {
    max-width: 364px;
    flex: 1 1 48%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
}

.event-data {
    display: flex;
    flex-direction: column;
    max-width: 364px;
    flex-wrap: wrap;
}

.guests {
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
}

.event-agenda {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    flex-wrap: wrap;
}

.agenda-title {
    letter-spacing: 1.28px;
}

.event-agenda span {
    display: flex;
    align-self: stretch;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
}

.event-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.event-footer button {
    width: 145px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 8px 16px;
    border-radius: 19px;
    border: none;
    color: #fff;
    background: linear-gradient(229.85deg, #00DAFC 15.12%, #0192FD 43.86%, #2959EB 66.92%, #5E0BCD 86.76%);
}

.event-footer span {
    display: flex;
    width: 85px;
    height: 24px;
    flex-direction: column;
    justify-content: flex-end;
    flex-shrink: 0;
    text-align: right;
}