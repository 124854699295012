.connection-card {
    width: 408px;
    height: 296px;
    min-width: 360px;
    min-height: 296px;
    border-radius: 8px;
    border: 1px solid rgba(56, 220, 6, 0.1);
    background: rgba(255, 255, 255, 0.08);
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    position: relative;
    backdrop-filter: blur(4px);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  
  .connection-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
  }
  
  .star-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    opacity: 1.0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  }
  
  .star-icon.starred,
  .star-icon:hover {
    opacity: 1;
    transform: scale(1.1);
  }
  
  /* Card Header */
  .card-header {
    width: 376px;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .profile-section {
    display: flex;
    gap: 8px;
  }
  
  .profile-image {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
    background-color: #1a1a4a;
    flex-shrink: 0;
  }
  
  .profile-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 272px;
  }
  
  .user-name {
    margin: 0 0 4px 0;
    font-size: 20px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
  }
  
  .bio-text {
    font-size: 12px;
    margin: 0;
    color: white;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.4;
  }
  
  .star-container {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .status-container {
    width: 376px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0;
    gap: 16px;
  }
  
  .status {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: white;
  }
  
  .status-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    flex-shrink: 0;
  }
  
  .status-green {
    background-color: #10b981;
  }
  
  .status-orange {
    background-color: #f59e0b;
  }
  
  
  .rate {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: white;
    gap: 6px; 
    
  }
  
  
  /* Content Section */
  .content-section {
    width: 376px;
    height: 113px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: hidden;
  }
  
  /* Description section */
  .description-section {
    overflow: hidden;
    height: 40px;
  }
  
  .description-text {
    font-size: 14px;
    color: #c0c0d0;
    line-height: 1.3;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .description-text.expanded {
    -webkit-line-clamp: initial;
    line-clamp: initial;
    max-height: none;
  }
  
  /* Interests section */
  .interests-section {
    overflow: hidden;
    margin-bottom: 4px;
  }
  
  .interests-title {
    font-size: 14px;
    margin: 0 0 8px 0;
    color: white;
    font-weight: 600;
  }
  
  .tags-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    height: 40px;
    align-items: center;
    color: white;
  }
  
  .interest-tag {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 4px 10px;
    border-radius: 9999px;
    font-size: 13px;
    font-weight: 600;
    white-space: nowrap;
    color: #02020d;
    display: inline-block;
    align-items: center;
    justify-content: center;
    max-width: calc((100% - 16px) / 3);
    height: 24px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: transform 0.2s ease;
  }
  
  .interest-tag:hover {
    transform: translateY(-1px); /* Slight lift effect on hover */
    background-color: rgba(255, 255, 255, 0.9); /* Slightly brighter on hover */
  }
  
  /* Remove this if you want to show all tags that fit in one line */
  .interest-tag:nth-child(n+4) {
    display: none;
  }
  
  .tags-container.has-more::after {
    content: "...";
    color: white;
    font-size: 14px;
    margin-left: 4px;
    align-self: center;
  }
  
  /* Action buttons */
  .action-buttons {
    width: 376px;
    height: 40px;
    display: flex;
    gap: 24px;
    justify-content: space-between;
    margin-top: auto;
  }
  
  .action-buttons button {
    width: 176px;
    height: 40px;
    padding: 8px 16px;
    border-radius: 16px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .view-profile-btn {
    background-color: transparent;
    color: white;
    border: 1px solid rgba(255, 255, 255, 1);
  }
  .message-icon{
    width: 45px;
    height: 30px;
    /* margin-right: 8px; */
  }
  .view-profile-btn:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .message-btn {
    background: rgba(41, 104, 254, 0.8);
    color: white;
    border: none;
  }
  
  .message-btn:hover {
    background: rgba(41, 104, 254, 1);
  }
  
  /* Responsive adjustments */
  @media (max-width: 1200px) {
    .connection-card {
      width: 380px;
      min-width: 340px;
    }
  
    .card-header,
    .status-container,
    .content-section,
    .action-buttons {
      width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .connection-card {
      width: 100%;
      min-width: 300px;
    }
  
    .action-buttons {
      gap: 16px;
    }
  
    .action-buttons button {
      width: calc(50% - 8px);
      padding: 8px 30px;
      font-size: 13px;
    }
  }
  
  @media (max-width: 480px) {
    .connection-card {
      padding: 12px;
    }
  
    .profile-image {
      width: 56px;
      height: 56px;
    }
  
    .user-name {
      font-size: 16px;
    }
  
    .bio-text {
      font-size: 13px;
    }
  
    .action-buttons button {
      font-size: 12px;
      padding: 8px;
    }
  
    .tags-container {
      max-height: 50px;
    }
  
    .interest-tag {
      padding: 3px 10px;
      font-size: 11px;
    }
  }