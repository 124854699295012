.profile-setup-step-1-child {
    position: absolute;
    top: 80px;
    right: 1034px;
    border-radius: 50%;
    background: linear-gradient(
      180deg,
      rgba(25, 0, 97, 0),
      rgba(25, 0, 97, 0.4) 20%,
      #160755 44.96%,
      #0a1b30
    );
    width: 302px;
    height: 302px;
  }
  .profile-setup-step-1-item {
    position: absolute;
    right: 155px;
    bottom: 16px;
    border-radius: 50%;
    background: linear-gradient(180deg, #000f61, #0a1730);
    width: 220px;
    height: 220px;
  }
  .yrect {
    position: absolute;
    top: -9px;
    right: 0;
  }
  .logo-child {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 19.5px 19.5px 0;
    background: linear-gradient(
      225.73deg,
      #00dafd,
      #0196fd 33.58%,
      #1573f4 56.63%,
      #3842e2 74.53%,
      #5f06ce
    );
    width: 40px;
    height: 39px;
  }
  .logo-item {
    position: absolute;
    top: calc(50% - 9.5px);
    left: calc(50% - 12px);
    width: 20px;
    height: 20px;
  }
  .logo,
  .logo1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 39px;
    overflow: hidden;
  }
  .logo {
    top: 32px;
    left: 24px;
    width: 160px;
  }
  .progress-bar-child {
    align-self: stretch;
    flex: 1;
    position: relative;
    border-radius: 16px;
    background: linear-gradient(
        180deg,
        rgba(72, 205, 255, 0.2),
        rgba(72, 205, 255, 0.4)
      ),
      linear-gradient(0deg, rgba(72, 205, 255, 0.19), rgba(72, 205, 255, 0.4));
  }
  .progress-bar1,
  .progress-state-child {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .progress-bar1 {
    flex: 1;
    border-radius: 10px;
    height: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .progress-state-child {
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid rgba(72, 205, 255, 0.8);
    box-sizing: border-box;
  }
  .inprogress,
  .progress-state-child,
  .success {
    position: absolute;
    border-radius: 50%;
  }
  .success {
    height: 5%;
    width: 5%;
    top: 47.5%;
    right: 47.5%;
    bottom: 47.5%;
    left: 47.5%;
    background: linear-gradient(
        180deg,
        rgba(30, 215, 96, 0.15),
        rgba(30, 215, 96, 0.3)
      ),
      linear-gradient(0deg, rgba(30, 215, 96, 0.09), rgba(30, 215, 96, 0.2));
  }
  .inprogress {
    height: 97.5%;
    width: 97.5%;
    top: 1.25%;
    right: 1.25%;
    bottom: 1.25%;
    left: 1.25%;
    background: linear-gradient(
        180deg,
        rgba(72, 205, 255, 0.2),
        rgba(72, 205, 255, 0.4)
      ),
      linear-gradient(0deg, rgba(72, 205, 255, 0.19), rgba(72, 205, 255, 0.4));
  }
  .div {
    position: absolute;
    top: calc(50% - 24px);
    left: calc(50% - 12px);
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 48px;
  }
  .progress-state {
    width: 48px;
    position: relative;
    height: 48px;
  }
  .progress-bar-item {
    align-self: stretch;
    width: 8px;
    position: relative;
    border-radius: 16px;
    background: linear-gradient(
        180deg,
        rgba(72, 205, 255, 0.2),
        rgba(72, 205, 255, 0.4)
      ),
      linear-gradient(0deg, rgba(72, 205, 255, 0.19), rgba(72, 205, 255, 0.4));
  }
  .inprogress1,
  .progress-state-item {
    position: absolute;
    border-radius: 50%;
  }
  .progress-state-item {
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(175, 175, 175, 0.5);
    box-sizing: border-box;
  }
  .inprogress1 {
    height: 5%;
    width: 5%;
    top: 47.5%;
    right: 47.5%;
    bottom: 47.5%;
    left: 47.5%;
    background: linear-gradient(
        180deg,
        rgba(72, 205, 255, 0.15),
        rgba(72, 205, 255, 0.3)
      ),
      linear-gradient(0deg, rgba(72, 205, 255, 0.09), rgba(72, 205, 255, 0.2));
  }
  .progress-bar-inner {
    align-self: stretch;
    width: 8px;
    position: relative;
    border-radius: 16px;
    background: linear-gradient(
        180deg,
        rgba(72, 205, 255, 0.2),
        rgba(72, 205, 255, 0.4)
      ),
      linear-gradient(0deg, rgba(72, 205, 255, 0.19), rgba(72, 205, 255, 0.4));
    opacity: 0;
  }
  .progress-state2 {
    width: 48px;
    position: relative;
    height: 48px;
    display: none;
  }
  .rectangle-div {
    align-self: stretch;
    width: 8px;
    position: relative;
    border-radius: 16px;
    background: linear-gradient(
        180deg,
        rgba(72, 205, 255, 0.2),
        rgba(72, 205, 255, 0.4)
      ),
      linear-gradient(0deg, rgba(72, 205, 255, 0.19), rgba(72, 205, 255, 0.4));
    display: none;
  }
  .progress-bar,
  .progress-bar4 {
    flex-direction: row;
    align-items: center;
  }
  .progress-bar4 {
    width: 147px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.08);
    height: 8px;
    display: none;
    justify-content: flex-start;
  }
  .progress-bar {
    align-self: stretch;
    display: flex;
    justify-content: center;
    gap: 16px;
    text-align: center;
    font-size: 24px;
  }
  .lets-get-to {
    font-size: 32px;
    letter-spacing: 0.08em;
    /* text-shadow: 0.8px 0 0#fff, 0 0.8px 0#fff, -0.8px 0 0#fff, 0-0.8px 0#fff; */
    color: white;
  }
  .lets-get-to,
  .pick-up-to,
  .tell-us-what {
    position: relative;
    font-weight: 200;
  }
  .tell-us-what {
    letter-spacing: 0.04em;
    /* text-shadow: 0.4px 0 0#fff, 0 0.4px 0#fff, -0.4px 0 0#fff, 0-0.4px 0#fff; */
    color: white;
    font-weight: bold;
  }
  .pick-up-to {
    font-size: 12px;
    /* text-shadow: 0.2px 0 0#fff, 0 0.2px 0#fff, -0.2px 0 0#fff, 0-0.2px 0#fff; */
    color: white;
  }
  .header {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
  }
  .div4 {
    position: absolute;
    letter-spacing: 0.04em;
    font-weight: 200;
    color: rgba(255, 255, 255, 0.5);
    /* text-shadow: 0.4px 0 0 rgba(255, 255, 255, 0.5),
      0 0.4px 0 rgba(255, 255, 255, 0.5), -0.4px 0 0 rgba(255, 255, 255, 0.5),
      0-0.4px 0 rgba(255, 255, 255, 0.5); */
      color: white;
      padding: 2px 10px;
  }
  .your-selected-topics-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
  }
  .search1 {
    position: relative;
    letter-spacing: 0.04em;
    font-weight: 200;
    /* text-shadow: 0.2px 0 0 rgba(255, 255, 255, 0.7),
      0 0.2px 0 rgba(255, 255, 255, 0.7), -0.2px 0 0 rgba(255, 255, 255, 0.7),
      0-0.2px 0 rgba(255, 255, 255, 0.7); */
      color: white;
  }
  .search-icon {
    width: 24px;
    position: relative;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .tag-name {
    position: relative;
    font-weight: 200;
    /* text-shadow: 0.2px 0 0#fff, 0 0.2px 0#fff, -0.2px 0 0#fff, 0-0.2px 0#fff; */
    color: white;
  }
  .dropdown-button {
    align-self: stretch;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 16px;
    box-sizing: border-box;
    gap: 8px;
    min-width: 140px;
  }
  .search,
  .search2 {
    align-self: stretch;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
  }
  .search2 {
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    height: 136px;
    align-items: center;
    justify-content: space-between;
    gap: 0;
    font-size: 12px;
    color: var(--primary-base-white);
  }
  .search {
    height: 48px;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    color: rgba(255, 255, 255, 0.7);
  }
  .frame-parent,
  .selected {
    align-self: stretch;
    flex: 1;
  }
  .frame-parent {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  .topic {
    position: relative;
    letter-spacing: 0.04em;
    font-weight: 200;
    /* text-shadow: 0.2px 0 0#fff, 0 0.2px 0#fff, -0.2px 0 0#fff, 0-0.2px 0#fff; */
    color: white;
  }
  .tick-icon {
    width: 14px;
    position: relative;
    height: 14px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .tag,
  .tag15 {
    border-radius: 80px;
    background-color: rgba(102, 0, 204, 0.24);
    border: 1px solid #60c;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 12px;
    gap: 4px;
  }
  .tag {
    display: flex;
  }

  .tag89{
    border-radius: 80px;
    border: 1px solid #48CDFF;
    background: rgba(72, 205, 255, 0.24);
    padding: 4px 12px;
  }

  .remove-btn{
    border-radius: 30%;
    border: 1px solid #e9ecee;
    background: rgba(72, 205, 255, 0.24);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .remove-icon {
    margin-left: 8px;
    font-size: inherit;
    color: white;
    cursor: pointer;
  }

  .tag15 {
    display: none;
  }
  .recommended {
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 8px;
    gap: 12px;
    text-align: right;
    font-size: 12px;
  }
  .header-parent,
  .recommended,
  .recommended-for-you-parent,
  .selected-tags,
  .tag-selection {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: auto;
  }
  .recommended-for-you-parent {
    flex: 1;
    flex-direction: column;
    gap: 8px;
  }
  .header-parent,
  .tag-selection {
    gap: 16px;
  }
  .tag-selection {
    height: 234px;
    flex-direction: row;
  }
  .header-parent {
    flex-direction: column;
  }
  .if-you-charge {
    align-self: stretch;
    position: relative;
    font-size: 12px;
    font-weight: 200;
    /* text-shadow: 0.2px 0 0#fff, 0 0.2px 0#fff, -0.2px 0 0#fff, 0-0.2px 0#fff; */
    color: white;
  }
  .header1 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
  }
  .div9,
  .hourly-rate {
    position: relative;
    line-height: 26px;
    font-weight: 200;
    /* text-shadow: 0.2px 0 0#fff, 0 0.2px 0#fff, -0.2px 0 0#fff, 0-0.2px 0#fff; */
    color: white;
  }
  .div9 {
    align-self: stretch;
    text-decoration: underline;
    border: 0;
  }

/* styles.css */
.custom-input {
  width: 40px;
  height: 30px;
  border: none;
  background: transparent;
  text-align: center;
  outline: none;
}

.custom-input::-webkit-outer-spin-button,
.custom-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-input:focus {
  /* ring: 0; */
  border: none;
}


  .collab-rate,
  .rate,
  .rate-confirmation,
  .value,
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .wrapper {
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    flex-direction: column;
    padding: 8px 8px;
  }
  .collab-rate,
  .rate,
  .rate-confirmation,
  .value {
    flex-direction: row;
    gap: 4px;
  }
  .collab-rate,
  .rate,
  .rate-confirmation {
    gap: 8px;
  }
  .collab-rate,
  .rate-confirmation {
    gap: 9px;
  }
  .collab-rate {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  .button,
  .switch2 {
    border-radius: 100px;
  }
  .button {
    position: absolute;
    top: 2px;
    left: 22px;
    box-shadow: 0 2px 4px rgba(39, 39, 39, 0.1);
    background-color: var(--primary-base-white);
    width: 20px;
    height: 20px;
    overflow: hidden;
  }

  .button22 {
    width: 120px;
    border-radius: 16px;
    background-color: rgba(41, 104, 254, 0.8);
    height: 40px;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    gap: 8px;
    display: flex;
  }
  .switch2 {
    width: 44px;
    position: relative;
    background-color: #00bc42;
    height: 24px;
  }
  .switch1,
  .title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .switch1 {
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
  .collab,
  .collab-preferences,
  .switch {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .switch {
    flex-direction: row;
  }
  .collab,
  .collab-preferences {
    align-self: stretch;
  }
  .collab {
    flex: 1;
    flex-direction: column;
    gap: 16px;
  }
  .collab-preferences {
    flex-direction: row;
    gap: 32px;
  }
  .msg-child {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px 8px 4px 8px;
    border: 2px solid var(--primary-base-white);
    box-sizing: border-box;
    width: 16px;
    height: 16px;
  }
  .msg-item {
    top: 5px;
    left: 4px;
    border-radius: 1px;
    background-color: var(--primary-base-white);
    width: 8px;
    height: 2px;
    display: none;
  }
  .msg,
  .msg-inner,
  .msg-item {
    position: absolute;
  }
  .msg-inner {
    top: 9px;
    left: 4px;
    border-radius: 1px;
    background-color: var(--primary-base-white);
    width: 6px;
    height: 2px;
    display: none;
  }
  .msg {
    height: 66.67%;
    width: 66.67%;
    top: 16.67%;
    right: 16.67%;
    bottom: 16.67%;
    left: 16.67%;
  }
  .button1,
  .messagelight {
    overflow: hidden;
    flex-shrink: 0;
  }
  .messagelight {
    width: 24px;
    position: relative;
    height: 24px;
    display: none;
  }
  .button1 {
    width: 176px;
    border-radius: 16px;
    border: 1px solid var(--primary-base-white);
    box-sizing: border-box;
    height: 40px;
    justify-content: center;
    padding: 8px 16px;
    gap: 8px;
    opacity: 0;
  }
  .button-parent,
  .button1,
  .button2 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .button2 {
    width: 176px;
    border-radius: 16px;
    /* background-color: rgba(41, 104, 254, 0.8); */
    height: 40px;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: center;
    padding: 8px 16px;
    box-sizing: border-box;
    gap: 8px;
  }
  .button-parent {
    align-self: stretch;
    justify-content: space-between;
    gap: 0;
    text-align: right;
  }
  .setup-1 {
    position: absolute;
    width: calc(100% - 510px);
    top: calc(50% - 382px);
    right: 255px;
    left: 255px;
    backdrop-filter: blur(40px);
    border-radius: 24px;
    background-color: rgba(0, 0, 41, 0.01);
    border: 1px solid rgba(174, 174, 255, 0.5);
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 40px;
    gap: 32px;
    font-size: 16px;
    font-family: Nexa;
  }
  .profile-setup-step-1 {
    width: 100%;
    position: relative;
    background-color: #000029;
    height: 1024px;
    overflow: hidden;
    text-align: left;
    font-size: 47px;
    color: var(--primary-base-white);
    font-family: NexaRegular;
  }






  /* -------------------------------------------------------- */

  .profile-setup-step-2-child {
    position: absolute;
    top: 80px;
    right: 1034px;
    border-radius: 50%;
    background: linear-gradient(180deg, #000029 8%, #33007b 62%, #60c);
    width: 302px;
    height: 302px;
  }
  .profile-setup-step-2-item {
    position: absolute;
    right: 155px;
    bottom: 16px;
    border-radius: 50%;
    background: linear-gradient(180deg, #300061, #0a1030);
    width: 220px;
    height: 220px;
  }
  .yrect {
    position: absolute;
    top: -9px;
    right: 0;
  }
  .logo-child {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 19.5px 19.5px 0;
    background: linear-gradient(
      225.73deg,
      #00dafd,
      #0196fd 33.58%,
      #1573f4 56.63%,
      #3842e2 74.53%,
      #5f06ce
    );
    width: 40px;
    height: 39px;
  }
  .logo-item {
    position: absolute;
    top: calc(50% - 9.5px);
    left: calc(50% - 12px);
    width: 20px;
    height: 20px;
  }
  .logo,
  .logo1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 39px;
    overflow: hidden;
  }
  .logo {
    top: 32px;
    left: 24px;
    width: 160px;
  }
  .progress-bar-child {
    align-self: stretch;
    flex: 1;
    position: relative;
    border-radius: 16px;
    background: linear-gradient(
        180deg,
        rgba(30, 215, 96, 0.2),
        rgba(30, 215, 96, 0.4)
      ),
      linear-gradient(0deg, rgba(30, 215, 96, 0.19), rgba(30, 215, 96, 0.4));
  }
  .progress-bar1,
  .progress-state-child {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .progress-bar1 {
    flex: 1;
    border-radius: 10px;
    height: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .progress-state-child {
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid rgba(30, 215, 96, 0.5);
    box-sizing: border-box;
  }
  .inprogress,
  .progress-state-child,
  .success {
    position: absolute;
    border-radius: 50%;
  }
  .success {
    height: 97.5%;
    width: 97.5%;
    top: 1.25%;
    right: 1.25%;
    bottom: 1.25%;
    left: 1.25%;
    background: linear-gradient(
        180deg,
        rgba(30, 215, 96, 0.2),
        rgba(30, 215, 96, 0.4)
      ),
      linear-gradient(0deg, rgba(30, 215, 96, 0.19), rgba(30, 215, 96, 0.4));
  }
  .inprogress {
    height: 5%;
    width: 5%;
    top: 47.5%;
    right: 47.5%;
    bottom: 47.5%;
    left: 47.5%;
    background: linear-gradient(
        180deg,
        rgba(72, 205, 255, 0.15),
        rgba(72, 205, 255, 0.3)
      ),
      linear-gradient(0deg, rgba(72, 205, 255, 0.09), rgba(72, 205, 255, 0.2));
  }
  .div {
    position: absolute;
    top: calc(50% - 24px);
    left: calc(50% - 12px);
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 48px;
  }
  .progress-state {
    width: 48px;
    position: relative;
    height: 48px;
  }
  .progress-bar-item {
    align-self: stretch;
    flex: 1;
    position: relative;
    border-radius: 16px;
    background: linear-gradient(
        180deg,
        rgba(72, 205, 255, 0.2),
        rgba(72, 205, 255, 0.4)
      ),
      linear-gradient(0deg, rgba(72, 205, 255, 0.19), rgba(72, 205, 255, 0.4));
  }
  .progress-state-item,
  .success1 {
    position: absolute;
    border-radius: 50%;
  }
  .progress-state-item {
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(72, 205, 255, 0.8);
    box-sizing: border-box;
  }
  .success1 {
    height: 5%;
    width: 5%;
    top: 47.5%;
    right: 47.5%;
    bottom: 47.5%;
    left: 47.5%;
    background: linear-gradient(
        180deg,
        rgba(30, 215, 96, 0.15),
        rgba(30, 215, 96, 0.3)
      ),
      linear-gradient(0deg, rgba(30, 215, 96, 0.09), rgba(30, 215, 96, 0.2));
  }
  .inprogress1,
  .progress-bar-inner {
    background: linear-gradient(
        180deg,
        rgba(72, 205, 255, 0.2),
        rgba(72, 205, 255, 0.4)
      ),
      linear-gradient(0deg, rgba(72, 205, 255, 0.19), rgba(72, 205, 255, 0.4));
  }
  .inprogress1 {
    position: absolute;
    height: 97.5%;
    width: 97.5%;
    top: 1.25%;
    right: 1.25%;
    bottom: 1.25%;
    left: 1.25%;
    border-radius: 50%;
  }
  .progress-bar-inner {
    align-self: stretch;
    width: 8px;
    position: relative;
    border-radius: 16px;
  }
  .progress-state-inner {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(175, 175, 175, 0.5);
    box-sizing: border-box;
  }
  .progress-state2 {
    width: 48px;
    position: relative;
    height: 48px;
    display: none;
  }
  .rectangle-div {
    align-self: stretch;
    width: 8px;
    position: relative;
    border-radius: 16px;
    background: linear-gradient(
        180deg,
        rgba(72, 205, 255, 0.2),
        rgba(72, 205, 255, 0.4)
      ),
      linear-gradient(0deg, rgba(72, 205, 255, 0.19), rgba(72, 205, 255, 0.4));
    display: none;
  }
  .progress-bar,
  .progress-bar4 {
    flex-direction: row;
    align-items: center;
  }
  .progress-bar4 {
    width: 147px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.08);
    height: 8px;
    display: none;
    justify-content: flex-start;
  }
  .progress-bar {
    align-self: stretch;
    display: flex;
    justify-content: center;
    gap: 16px;
    text-align: center;
    font-size: 24px;
  }
  .upload-a-profile {
    position: relative;
    letter-spacing: 0.04em;
    font-weight: 200;
    font-weight: bold;
    /* text-shadow: 0.4px 0 0#fff, 0 0.4px 0#fff, -0.4px 0 0#fff, 0-0.4px 0#fff; */
    color: white;
  }
  .dding-a-photo {
    position: relative;
    font-size: 12px;
    font-weight: 200;
    /* text-shadow: 0.2px 0 0#fff, 0 0.2px 0#fff, -0.2px 0 0#fff, 0-0.2px 0#fff; */
    color: white;
  }
  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
  }
  .layer-2-icon {
    position: absolute; /* Ensures it fills the container */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    object-fit: cover; /* Ensures the image covers the entire area */
    border-radius: 50%; /* Optional: Makes it circular */
    border: 1px solid #fff;
}

.profile-logo {
    position: absolute;
    bottom: 5px;
    right: 3px;
    width: 30px;
    height: auto;
    background-color: white; /* Optional: Make the logo stand out */
    border-radius: 50%;
    padding: 3px;
    border: 1px solid #fff;
    backdrop-filter: blur(2px);
    padding: 6px;
}

  .edit {
    width: 100%;
    backdrop-filter: blur(4px);
    /* border-radius: 50%; */
    background-color: transparent;
    /* border: 1px solid #fff; */
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .edit-wrapper {
    width: 170px;
    border-radius: 75px;
    height: 170px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    /* background-image: url(/public/frame-220@3x.png); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    margin-right: 30%;
  }
  .profile-picture {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
  .div3 {
    position: relative;
    letter-spacing: 0.04em;
    font-weight: 200;
    /* text-shadow: 0.2px 0 0 rgba(255, 255, 255, 0.7),
      0 0.2px 0 rgba(255, 255, 255, 0.7), -0.2px 0 0 rgba(255, 255, 255, 0.7),
      0-0.2px 0 rgba(255, 255, 255, 0.7); */
      color: white;
  }
  .bio1 {
    align-self: stretch;
    flex: 1;
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-content: flex-end;
    /* padding: 8px; */
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
  }
  .bio2 {
    flex: 1;
    border-radius: 16px;
    background-color: transparent;
    border: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-content: flex-end;
    height: 100%;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 15px;
    padding: 10px;
  }


  .bio,
  .top-section {
    display: flex;
    justify-content: flex-start;
  }
  .bio {
    flex: 1;
    height: 212px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  .top-section {
    align-self: stretch;
    flex-direction: row;
    align-items: center;
    gap: 32px;
  }
  .your-profile-is {
    align-self: stretch;
    position: relative;
    font-size: 12px;
    font-weight: 200;
    /* text-shadow: 0.2px 0 0#fff, 0 0.2px 0#fff, -0.2px 0 0#fff, 0-0.2px 0#fff; */
    color: white;
  }
  .header2 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
  }
  .radio-button-child {
    width: 16px;
    position: relative;
    border-radius: 50%;
    background-color: #48cdff;
    height: 16px;
  }
  .radio-button1 {
    width: 32px;
    border-radius: 26px;
    background-color: rgba(72, 205, 255, 0.08);
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .button-label {
    position: relative;
    line-height: 26px;
    font-weight: 200;
    /* text-shadow: 0.2px 0 0#fff, 0 0.2px 0#fff, -0.2px 0 0#fff, 0-0.2px 0#fff; */
    color: white;
  }
  .radio-button,
  .radio-button-parent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
  }
  .radio-button-parent {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
  }
  .radio-button-item {
    width: 1px;
    position: relative;
    border-radius: 50%;
    background-color: #48cdff;
    height: 1px;
    opacity: 0;
  }
  .button-label1 {
    flex: 1;
    position: relative;
    line-height: 26px;
    font-weight: 200;
    /* text-shadow: 0.2px 0 0#fff, 0 0.2px 0#fff, -0.2px 0 0#fff, 0-0.2px 0#fff; */
    color: white;
  }
  .radio-button2 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
  }
  .only-users-you {
    align-self: stretch;
    position: relative;
    font-size: 14px;
    font-weight: 200;
    /* text-shadow: 0.2px 0 0#fff, 0 0.2px 0#fff, -0.2px 0 0#fff, 0-0.2px 0#fff; */
    color: white;
  }
  .collab-rate,
  .visibility-status {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
  }
  .collab-rate {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  .socialsfacebook-icon {
    width: 64px;
    position: relative;
    height: 64px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .text {
    position: relative;
    letter-spacing: 0.04em;
    font-weight: 200;
    /* text-shadow: 0.2px 0 0#fff, 0 0.2px 0#fff, -0.2px 0 0#fff, 0-0.2px 0#fff; */
    color: white;
  }
  .link-icon {
    width: 24px;
    position: relative;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .button {
    align-self: stretch;
    border-radius: 16px;
    background-color: rgba(41, 104, 254, 0.8);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    gap: 8px;
  }
  .fb {
    width: 96px;
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;
  }
  .fb,
  .socials,
  .yt {
    display: flex;
    align-items: center;
  }
  .yt {
    width: 97px;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
  }
  .socials {
    align-self: stretch;
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
    text-align: right;
  }
  .msg-child {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px 8px 4px 8px;
    border: 2px solid #fff;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
  }
  .msg-item {
    top: 5px;
    left: 4px;
    border-radius: 1px;
    background-color: #fff;
    width: 8px;
    height: 2px;
    display: none;
  }
  .msg,
  .msg-inner,
  .msg-item {
    position: absolute;
  }
  .msg-inner {
    top: 9px;
    left: 4px;
    border-radius: 1px;
    background-color: #fff;
    width: 6px;
    height: 2px;
    display: none;
  }
  .msg {
    height: 66.67%;
    width: 66.67%;
    top: 16.67%;
    right: 16.67%;
    bottom: 16.67%;
    left: 16.67%;
  }
  .messagelight {
    width: 24px;
    position: relative;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .button5 {
    width: 176px;
    border-radius: 16px;
    border: 1px solid #ffffff !important;
    height: 40px;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    gap: 8px;
    background-color: transparent;
  }
  .button5,
  .button6,
  .signup-box {
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
  }
  .button6 {
    width: 250px;
    border-radius: 16px;
    background-color: rgba(41, 104, 254, 0.8);
    height: 40px;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    gap: 8px;
    margin-left: 35%;
  }
  .signup-box {
    position: absolute;
    width: calc(100% - 510px);
    top: calc(50% - 433px);
    right: 255px;
    left: 255px;
    backdrop-filter: blur(40px);
    border-radius: 24px;
    background-color: rgba(0, 0, 41, 0.01);
    border: 1px solid rgba(174, 174, 255, 0.5);
    height: 866px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 40px;
    gap: 32px;
    font-size: 16px;
    font-family: Nexa;
  }
  .profile-setup-step-2 {
    width: 100%;
    position: relative;
    background-color: #000029;
    height: 1024px;
    overflow: hidden;
    text-align: left;
    font-size: 47px;
    color: #fff;
    font-family: NexaRegular;
  }


  .header-signup{
    margin-left: 20%;
  }

  .youre-all-set{
    color: white;
    font-weight: bold;
  }

  .your-profile-will{
    color: white;
  }
  .happy-icon{
    margin-left: 30%;
    width: 40%;
  }


  /* .search-bar {
    align-self: stretch;
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    gap: 0;
  } */

  .search-bar {
    align-self: stretch;
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    position: relative;
  }
  
  .search-input {
    flex: 1; /* Makes input take available space */
    border: none;
    outline: none;
    background: transparent;
    color: white;
    font-size: 16px;
    padding: 12px 40px 12px 12px; /* Space for the icon */
  }
  
  .search-icon {
    position: absolute;
    right: 16px; /* Align icon inside input */
    color: white;
    font-size: 20px;
    cursor: pointer;
  }

  .selected-tags{
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 8px;
    gap: 12px;
    text-align: right;
    font-size: 12px;
  }
  
/* Modal Overlay (Background Blur) */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent black */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

/* Modal Content Box with Updated Background */
.modal-content {
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2); /* Soft gradient */
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
  width: 350px;
  text-align: center;
  position: relative;
  animation: slideIn 0.3s ease-in-out;
}

/* Modal Heading */
.modal-content h2 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #333;
}

/* Input Styling */
.modal-content input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  margin-bottom: 15px;
  outline: none;
  transition: border 0.3s ease;
}

.modal-content input:focus {
  border: 1px solid #007bff;
}

/* Button Container */
.modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

/* Save Button */
.modal-buttons button {
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Save Button Styling */
.modal-buttons button[type="submit"] {
  background: #007bff;
  color: white;
}

.modal-buttons button[type="submit"]:hover {
  background: #0056b3;
}

/* Cancel Button */
.modal-buttons button:last-child {
  background: #ccc;
  color: #333;
}

.modal-buttons button:last-child:hover {
  background: #999;
}

/* Close Button (Top-Right) */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 18px;
  cursor: pointer;
  color: #888;
  transition: color 0.2s;
}

.close-button:hover {
  color: #333;
}

/* Smooth Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}

.error-message{
  color: red;
}
