/* Main layout */
.connections-page-layout {
    width: 1352px;
    max-width: 100%;
    max-height: 816px;
    margin: 10px auto 0;
    padding: 0 32px 32px 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  /* Header styling */
  .connection-header {
    /* width: 1272px; */
    max-width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .connection-left-arrow {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
  
  .connection-header h2 {
    margin: 0;
    color: white;
    font-size: 24px;
    font-weight: 600;
  }
  
  /* Grid layout for connections */
  .connections-grid {
    /* width: 1272px; */
    max-width: 100%;
    max-height: 772px;
    padding-bottom: 10px;
    display: grid;
    /* grid-template-columns: repeat(3, 1fr);
    flex-direction: column;
    gap: 24px; */
  }
  
  .connection-row {
    /* width: 1272px; */
    max-width: 100%;
    min-height: 296px;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: flex-start;
  }
  
  /* No connections message */
  .no-connections {
    width: 100%;
    text-align: center;
    color: white;
    font-size: 18px;
    margin-top: 40px;
  }
  
  /* Pagination controls */
  .pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 24px;
    margin-bottom: 40px;
  }
  
  .pagination-button {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .pagination-button:hover:not(:disabled) {
    background: rgba(255, 255, 255, 0.15);
  }
  
  .pagination-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .pagination-button.active {
    background: rgba(41, 104, 254, 0.8);
    border-color: rgba(41, 104, 254, 0.8);
  }
  
  .pagination-ellipsis {
    color: white;
    margin: 0 4px;
  }

  
  .scroll-top-button {
    position: fixed;
    width: 40px;
    height: 40px;
    bottom: 40px;
    right: 40px;
    border-radius: 36px;
    border: 1px solid;
    border-image-source: linear-gradient(317.92deg, #FFFFFF 1.48%, rgba(0, 0, 0, 0) 67.95%), 
                        linear-gradient(166.93deg, #AFAFAF 3.24%, rgba(96, 96, 96, 0) 96.43%);
    background: rgba(241, 243, 246, 0.32);
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 9999; /* Increase z-index to ensure it's above other elements */
    transition: transform 0.3s ease;
    color: white;
  }
  
  .scroll-top-button:hover {
    transform: translateY(-5px);
  }
  
  /* Responsive adjustments */
  @media (max-width: 1400px) {
    .connections-page-layout,
    .connection-header,
    .connections-grid,
    .connection-row {
      width: 100%;
    }
    
    .connections-page-layout {
      padding: 0 20px 32px 20px;
    }
  }
  
  @media (max-width: 1200px) {
    .connections-page-layout {
      margin-top: 10px;
    }
    
    .connection-row {
      justify-content: center;
    }
  }
  
  @media (max-width: 768px) {
    .connections-page-layout {
      margin-top: 100px;
      padding: 0 16px 32px 16px;
      gap: 16px;
    }
    
    .connection-header {
      height: auto;
      margin-bottom: 8px;
    }
    
    .connection-header h2 {
      font-size: 20px;
    }
    
    .connections-grid {
      gap: 16px;
      padding-bottom: 0px;
    }
    
    .connection-row {
      gap: 16px;
    }
  }
  
  @media (max-width: 480px) {
    .connections-page-layout {
      margin-top: 80px;
      padding: 0 12px 24px 12px;
    }
    
    .connection-header h2 {
      font-size: 18px;
    }
  }
  