.user_profileinfo_title {
    -webkit-text-stroke-width: 0.4px;
    -webkit-text-stroke-color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.64px;
}

.user_profileinfo_subtitle {
    color: #FFF;
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.56px;
}

.profilepage_container {
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.profilepage_container_edit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;

    flex-shrink: 0;
}

.profilepage_container_editTitle {
    display: flex;
    align-items: center;
    gap: 16px
}

.profilepage_container_editTitle img {
    width: 32px;
    height: 32px;
    transform: rotate(90deg);
    cursor: pointer;
}

.profilepage_container_editTitle span {
    -webkit-text-stroke-width: 0.8px;
    -webkit-text-stroke-color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 1.92px;

}

.profilepage_container_editButton {
    display: flex;
    align-items: center;
    gap: 24px;
}

.profilepage_container_cancelButton,
.profilepage_container_saveButton {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    text-align: right;
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.64px;
    cursor: pointer;
}

.profilepage_container_saveButton {
    background: rgba(41, 104, 254, 0.80);

}

.profilepage_container_cancelButton {
    border: 1px solid #FFF;
}

.profilepage_container_cancelButton:hover {
    border: 1px solid #48CDFF;
    /* background: rgba(72, 205, 255, 0.24); */
}

.profilepage_container_saveButton:hover {
    background: rgba(100, 145, 252, 0.8);

}


.profilepage_container_cancelButton img,
.profilepage_container_saveButton img {
    width: 24px;
    height: 24px;
}


.profilepage_container_main {
    flex: 1;
    height: 67vh;
    max-height: 67vh;
    overflow-y: auto;
}

.profilepage_container_main::-webkit-scrollbar {
    display: none;
}


.profilepage_container_info {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: 24px;
}

.profilepage_container_info>* {
    flex-shrink: 0;
    overflow-y: auto;
    max-height: 100%;
    overflow: visible;
}


/* top */

.userinfo_container {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    gap: 40px;
    justify-content: space-between;
    width: 100%;
    flex-shrink: 0;
}

.userinfo_img_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.userinfo_img_container span {
    -webkit-text-stroke-width: 0.4px;
    -webkit-text-stroke-color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.64px;
}

.userinfo_profileImage_container {
    display: flex;
    width: 240px;
    height: 240px;
    justify-content: center;
    align-items: flex-end;
    border-radius: 165px;
    background-color: rgba(170, 170, 243, 0.462);
    position: relative;
}

.userinfo_profileImage_container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 165px;
}

.userinfo_profileImage_edit {
    display: flex;
    width: 32px;
    height: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    position: absolute;
    right: 16px;
    bottom: 16px;

    border-radius: 16px;
    border: 1px solid #FFF;
    background: rgba(255, 255, 255, 0.16);
    backdrop-filter: blur(2px);

}

.userinfo_profileImage_edit img {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
}

.profileimg_edit {
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.userinfo_data_container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    padding: 16px 0px;
}

.userinfo_data_containerTop,
.userinfo_data_containerBottom {
    display: flex;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    flex: 1;
}

.userinfo_data_containerTop_edit {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    /* border: 1px solid red */
}


.userinfo_data_containerTop_edit span {
    align-self: stretch;
    -webkit-text-stroke-width: 0.4px;
    -webkit-text-stroke-color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.64px;
}

.userinfo_data_containerBottom_left,
.userinfo_data_containerBottom_right {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    /* border: 1px solid red */
}

.userinfo_data_containerBottom_left_top,
.userinfo_data_containerBottom_right_top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

}

.userinfo_data_containerBottom_collab {
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 12px;
}

.userinfo_data_containerBottom_collab span,
.userinfo_data_containerBottom_rate span .userinfo_data_containerBottom_rate_amount {
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: 26px;
}

.userinfo_data_containerBottom_rate_amount {
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: 8%;
    text-underline-offset: 16%;
    text-underline-position: from-font;
}

.userinfo_data_containerBottom_rate {
    display: flex;
    align-items: center;
    gap: 9px;
}

.userinfo_data_containerBottom_rate_data {
    display: flex;
    align-items: center;
    gap: 8px;
}

.userinfo_data_containerBottom_rate_value {
    display: flex;
    align-items: center;
    gap: 4px;
}

.userinfo_data_containerBottom_rate_value div {
    display: flex;
    padding: 8px 16px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.80);
}

.userinfo_data_containerBottom_rate_value input {
    width: 40px;
    border: none;
    outline: none;
    background-color: inherit;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: 26px;
    text-decoration-line: underline;
}


/* UserBioCSS */

.userbio_container {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    gap: 24px;
    justify-content: space-between;
    width: 100%;
    max-height: 262px;
    flex-shrink: 0;
    /* border: 1px solid red; */
}

.userbio_sub_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
}

.userbio_sub_container_top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.userbio_sub_container_bio {
    display: flex;
    padding: 8px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    background: rgba(255, 255, 255, 0.08);
}

.userbio_sub_container_bio textarea {
    flex: 1;
    border-radius: 16px;
    background-color: transparent;
    /* border: 0; */
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-content: flex-end;
    height: 100%;
    width: 100%;
    color: #FFF;
    font-size: 15px;
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    border: none;
    outline: none;
    resize: none;
}

.userbio_sub_container_topics {
    display: flex;
    align-items: stretch;
    gap: 16px;
    flex: 1;
    align-self: stretch;
    width: 100%;
}

.userbio_sub_container_selected_topics,
.userbio_sub_container_rec_topics {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
    height: 100%;
}

.userbio_sub_container_topics_title {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: #666161;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.56px;
}

.userbio_sub_container_topics_search {
    align-self: stretch;
}

.userbio_sub_container_rec_topics_items {
    display: flex;
    flex-wrap: wrap;
    padding: 8px;
    gap: 12px;
    align-items: flex-start;
    align-content: flex-start;
    overflow-y: auto;
    flex-grow: 1;
    justify-content: flex-start;
    max-height: 176px;
}

.userbio_sub_container_rec_topics_items::-webkit-scrollbar {
    display: none;
}

.selectedTag {
    display: flex;
    padding: 4px 12px;
    align-items: center;
    gap: 4px;
    border-radius: 80px;
    border: 1px solid #60C;
    background: rgba(102, 0, 204, 0.24);

}

/* .selected {
    align-self: stretch;
    flex: 1;
} */

.rec_topic {
    text-align: right;
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.48px;
}

.userbio_sub_container_selected_topics_items {
    display: flex;
    padding: 8px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 12px;
    flex: 1 0 0;
    align-self: stretch;
    flex-wrap: wrap;
    max-height: 113px;
    overflow-y: scroll;
}

.userbio_sub_container_selected_topics_items::-webkit-scrollbar {
    display: none;
}

.selected_topic {
    display: flex;
    padding: 4px 12px;
    align-items: center;
    gap: 4px;
    border-radius: 80px;
    border: 1px solid #48CDFF;
    background: rgba(72, 205, 255, 0.24);
    text-align: right;
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.48px;
    cursor: pointer;
}


/* usersocial */
.usersocial_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}

.usersocial_sub_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.usersocial_subTitle {
    align-self: stretch;
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
}

.usersocial_profile_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.usersocial_profile_container_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 96px;
}

.usersocial_profile_item_img {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.usersocial_profile_status_button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}

.usersocial_profile_item_status,
.usersocial_profile_unlink {
    display: flex;
    width: 81px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 16px;
    padding: 8px 16px;
}

.usersocial_profile_unlink {
    border: 1px solid #fff;
}

.usersocial_profile_item_status {
    background: rgba(41, 104, 254, 0.80);
}

.usersocial_profile_unlink img {
    width: 24px;
    height: 24px;
}

.usersocial_profile_item_status span {
    text-align: right;
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.64px;

}




.collab {
    color: orange;
}

.test {
    color: greenyellow;
}