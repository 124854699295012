:root {
  --theme-bg-light: #19193e;
  --theme-bg: #000029;
}

.theme-bg {
  background-color: #000029;
}

.theme-bg-light {
  background: #19193e !important;
}

.height-100 {
  height: 100vh !important;
}

.clr-white {
  color: white !important;
}

p {
  margin-bottom: 0 !important;
}

input:-webkit-autofill {
  background-color: #000029 !important;
  /* Dark background */
  color: #fff !important;
  /* Light text */
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #000029 inset !important;
  /* Override the background color */
  -webkit-text-fill-color: #fff !important;
  /* Text color */
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #000029 inset !important;
  /* Keep the background color on hover/focus */
  -webkit-text-fill-color: #fff !important;
  /* Keep text color */
}

.fl-r {
  float: right;
}

.fl-l {
  float: left;
}

.text-trans-none {
  text-transform: none;
}

.theme-bg-light {
  background-color: var(--theme-bg-light) !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 0px
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--theme-bg-light);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

.mr-2 {
  margin-right: 2rem !important;
}

.ml-1 {
  margin-left: 1%;
}

.ml-3 {
  margin-left: 3% !important;
}

.ml-80p {
  margin-left: 80px !important;
}

.mb-2 {
  margin-bottom: 2% !important;
}

.mt-10p {
  margin-top: 10px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.px-4r {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.my-2 {
  margin-top: 2% !important;
  margin-bottom: 2% !important;
}