/* Main Container */
.main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 5%;
  gap: 24px;
  position: relative;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 41, 0.96);
  backdrop-filter: blur(12px);
  border-radius: 24px 0px 0px 24px;
  box-sizing: border-box;
  overflow: hidden;
}

.user-description{
color:#48CDFF;
font-size:16px;
margin:4px 0 0;
}
/* Chat Header */
.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: relative;
  width: 100%;
  background: rgba(0, 0, 41, 0.96);
  backdrop-filter: blur(12px);
  border-radius: 24px 0px 0px 24px;
  margin-left: 20px;
}

.left-items {
  display: flex;
  align-items: center;
  gap: 16px;
}

.chevron-icon img {
  cursor: pointer;
  width: 24px;
  height: 24px;
  transform: rotate(90deg);
}

.page-title {
  font-size: 24px;
  color: white;
  font-weight: bolder;
}

.right-item img {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
}

/* Chat Section */
.chat-section {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 24px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  color: white;
  margin-left: 20px;
}

/* Chat List Container */
.chatlist {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 436px;
  gap:15px;
  height: calc(100% - 20px);
  border-radius: 12px;
  margin-bottom: 20px;
  margin-left: 20px;
}

/* Search Bar */
.user-search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 16px;
  background-color: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: white;
  font-size: 14px;
  outline: none;
}

/* Contact Box */
.contact-box {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 20px;
  flex-grow: 1;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

/* Users List */
.users-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 20px;
}

/* User Card */
.user-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
border-radius:20px;
  padding: 10px;
  transition: background-color 0.1s;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
}


.user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

/* Message Info */
.message-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.new-message-count {
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
gap:30px;
 flex:none;
order:0;
flex-grow:0;
 background: #2968fe;
  color: white;
  padding: 8px;
  border-radius: 8px;
  width: 24px;
  height: 24px;
}

.last-seen {
  font-size: 12px;
  color: #b3b3b3;
  margin-top: 8px;
}

/* Chat Area */
.chat-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  margin-bottom: 20px;
  margin-right: 30px;
  color: white;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
  overflow: hidden;
  width: 100%;
  transition: width 0.3s ease-in-out;
}

/* When showProfile is true, reduce the width to 50% */
.chat-area.show-profile {
  width: 50%;
}

/* Centered Content */
.center-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}


.message-icon{
width:80px;
height:auto;
}

.message-text{
font-size:16px;
color:white;
max-width:400px;
text-align:center;
font-weight:bolder;

}

/* Button Styling */
.message-button {
  margin-top: 24px;
  padding: 10px 20px;
  background-color: #2968fe;
  color: white;
  border-radius: 16px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}


.message-button:hover {
  background-color: #1b50c4;
}

.message-button .button-icon {
  width: 30px;
}

.button-icon{
width:30px;
height:auto;
}

.user-card:hover{

background-color:rgba(255,255,255,0.1);

}

.user-card.selected{

background-color:rgba(255,255,255,0.2);

}

.user-name {
  font-size: 18px;
  height: 24px;
  font-weight: bolder;
  letter-spacing: 0.08rem;
  color: white;
  
  /* Prevent text wrapping */
  white-space: nowrap;
  
  /* Hide overflow and add "..." if text is too long */
  overflow: hidden;
  text-overflow: ellipsis;
  
  /* Optional: Ensure it does not exceed the parent container */
  max-width: 100%;
  display: block;
}

.user-info{
display:flex;
flex-direction:column;
flex-grow:1;
}

.unread-count{
background-color:#2968FE;
color:white;
padding:4px 8px;
border-radius:6px;
font-size:12px;
margin-right:10px;


}

/* Responsive Design - Mobile/Tablet */
@media (max-width: 768px) {
  .chat-section {
    flex-direction: column;
  }

  .chatlist {
    width: 100%;
    margin-left: 0;
    margin-bottom: 16px;
  }

  .chat-area {
    width: 100%;
    margin-right: 0;
  }

  .page-title {
    font-size: 18px;
  }

  .chevron-icon img {
    width: 20px;
    height: 20px;
  }

  .right-item img {
    width: 35px;
    height: 35px;
  }

  .user-name {
    font-size: 16px;
  }

  .user-description {
    font-size: 14px;
  }

  .message-button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .page-title {
    font-size: 16px;
  }

  .user-name {
    font-size: 14px;
  }

  .user-description {
    font-size: 12px;
  }

  .message-text {
    font-size: 14px;
  }

  .message-button {
    padding: 6px 12px;
    font-size: 12px;
  }

  .message-icon {
    width: 60px;
  }

  .new-message-count {
    width: 20px;
    height: 20px;
    font-size: 10px;
  }
}



/*Chat Area CSS */
/* Chat Area */
.chat-area {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
  height: calc(100% - 50px);
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  margin-bottom: 20px;
  margin-right: 30px;
  color: white;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
  overflow: hidden;
  width: 100%;
  transition: width 0.3s ease-in-out;
}

/* Header Section */
.chatarea-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}


.chat-user-info {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.username {
  font-size: 18px;
  font-weight: bold;
}

.menu-icon {
  font-size: 20px;
  cursor: pointer;
}

/* Chat Content */
.chat-content {
  flex-grow: 1;
  width: 100%;
  overflow-y: auto;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  max-height: 450px;
  scroll-behavior: smooth;
}

/* Message container */
.message-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.sent-container {
  justify-content: flex-end;
}

.received-container {
  justify-content: flex-start;
}

.message {
  padding: 10px;
  margin: 5px 0;
  max-width: 60%;
  border-radius: 20px;
  word-wrap: break-word;
  position: relative;
}

.message-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

/* Timestamp inside message */
.message-time {
  font-size: 0.75em;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 5px;
  text-align: right;
}

/* Sent & Received Message Colors */
.sent {
  background-color: #2968FECC;
  border-bottom-right-radius: 0px;
}

.received {
  background-color: #47464CCC;
  border-bottom-left-radius: 0px;
}

/* Dummy Image Fix */
.message-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 10px;
}

/* Chat Input */
.chat-input-section {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
gap:10px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.message-input {
  flex-grow: 1;
  padding: 10px;
  border: none;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  color: white;
  font-size: 16px;
  outline: none;
  margin: 0 10px;
}

.send-button {
  background-color: rgba(0, 123, 255, 0.8);
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.send-icon {
  font-size: 18px;
  color: white;
}


/* Chat Profile CSS */

/* Chat Profile */
.chat-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 50px);
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 20px;
  color: white;
  width: 50%;
  margin-right: 30px;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.2);

}

/* Close Button (X Symbol) */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 6px; /* Reduced size */
  border-radius: 50%;
  cursor: pointer;
}

.close-button:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.icon {
  width: 24px;
  height: 24px;
}

/* Profile Section */
.profile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
}

.profile-image {
  width: 120px; /* Increased size */
  height: 120px; /* Increased size */
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid rgba(255, 255, 255, 0.2);

}

.profile-name {
  font-size: 20px;
  font-weight: bold;
  color: white;
}

/* Icons Section */
.icons-section {
  display: flex;
  gap: 70px;
}

.icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* Icon Styling */
.icon {
  width: 50px;  /* Increased width of the icon */
  height: 50px; /* Increased height of the icon */
  border-radius: 50%;  /* Round border around the icon */
  padding: 8px;
}

.icon-label {
  margin-top: 8px;
  font-size: 16px; /* Same font size as profile-name */
  font-weight: bold; /* Matching the font weight */
  color: white;
  text-align: center;
}

/* Profile Options Section */
.profile-options {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.option-item {
  display: flex;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
}

.option-item span {
  color: white;
  font-size: 16px;
font-weight:bold;
}

.option-item:hover {
cursor:pointer;
}

/* Adjustments for small screens */
@media (max-width: 600px) {
  .chat-profile {
    width: 100%;
    margin-right: 0;
  }

  .profile-image {
    width: 100px;
    height: 100px;
  }

  .profile-name {
    font-size: 18px;
  }

  .icons-section {
    flex-direction: column;
    gap: 10px;
  }

  .icon {
    width: 30px;
  }

  .icon-label {
    font-size: 14px;
  }
}

.icon-border{
  background-color: rgba(255, 255, 255, 0.1);

border: 2px solid rgba(255, 255, 255, 0.2);  /* Visible border around the icon */


}



/* EMpty Chat Area CSS */

.chat-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.center-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.empty-chat-icon {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.message-text {
  font-size: 18px;
  color: #666;
  margin-top: 10px;
  max-width: 80%;
}

.message-button {
  background-color: #2968fe;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
}

.send-message-icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

