.sidebar.pro-inner-item {
    padding: 0.5rem 0.9rem 0.5rem 0.9rem;
}

.sidebar {
    z-index: 1005 !important;
    position: fixed;
    height: 100%;
    overflow-y: auto;
    transition: width 0.3s ease;
    width: 80px;
    /* Default collapsed width */
    background-color: rgba(15, 15, 44, 0.9);
    /* Dark background with some transparency */
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    /* Subtle border */
}

/* Sidebar expanded state on hover */
.sidebar:hover {
    width: 250px;
    /* Expanded width */
    background-color: rgba(0, 0, 41, 0.5) !important;
    /* More transparent dark background */
    backdrop-filter: blur(5px);
    /* Slightly stronger blur */
    -webkit-backdrop-filter: blur(5px);
}

/* Content area should adapt to sidebar width */
.main-content {
    margin-left: 80px;
    /* Match the collapsed sidebar width */
    transition: margin-left 0.3s ease;
    width: calc(100% - 80px);
}

/* Adjust content when sidebar expands */
.sidebar:hover~.main-content {
    margin-left: 80px;
    /* Keep content in place when sidebar expands */
}

.pro-sidebar .pro-sidebar-inner,
.pro-sidebar .pro-sidebar-content,
.pro-sidebar .pro-sidebar-header,
.pro-sidebar .pro-sidebar-footer,
.pro-sidebar .pro-menu {
    background-color: transparent !important;
}

.sidebar-name {
    padding-left: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 100%;
    color: #fff;
    /* background-color: #1E1E1E; */
    white-space: nowrap;
    overflow: hidden;
}

.item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebar-item {
    /* color: #000000;
    padding-left: 1rem; */
    color: #ffffff;
    padding: 0.4rem 1rem;
    width: 90%;
    /* padding-top: 0.4rem;
    padding-bottom: 0.4rem; */
    cursor: pointer;
    margin-top: 0.5rem;
    border-radius: 10px;

    border: 2px solid transparent;
    transition: background-color 0.2s ease;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;
    background-color: transparent;
}

/* .sidebar-item:hover {
    background-color: #00000020;
} */

.active {
    /* border: 2px solid #aca6a6 !important;
    background-color: #aca6a6; */
    color: #fff !important;
}

.active:hover {
    /* border: 2px solid #aca6a6 !important; */
    color: #fff !important;
}

a {
    text-decoration: none !important;
}

.logout-btn {
    cursor: pointer;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 3% 3% 3% 2% !important;
}

.icon-container {
    margin-bottom: 20px;
    padding-bottom: 10px;
}

.icon-names {
    font-size: small;
    margin-left: 10px;
}

.fs-small {
    font-size: small
}

.sidebar-icon {
    font-size: 25px !important;
    min-width: 25px;
    /* Ensure icon has consistent width */
    margin-right: 15px !important;
    filter: brightness(1.2);
    /* Make icons slightly brighter for better visibility */
}

/* .sidebar-icon:active {
    border-radius: 10px;
    background: #1084ff;
} */

.pro-menu-item.active .pro-item-content {
    border-radius: 10px;
    background: #1084ff;
}

.pro-menu-item .pro-item-content:hover {
    border-radius: 10px;
    background: #6eaaea;
}


.sidebar-text {
    opacity: 0;
    /* Hidden by default */
    transition: opacity 0.1s ease;
    transition-delay: 0.1s;
}

/* Show text when sidebar is expanded */
.sidebar:hover .sidebar-text {
    opacity: 1;
}

.sidebar-header {
    transition: all 0.3s ease;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item {
    padding: 8px 25px 8px 15px !important;
}

.main-logo-container {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 8px;
    /* Add a small padding if needed */
}

.logo-image {
    flex-shrink: 0;
    /* Prevents the image from moving */
}

.main-logo-text {
    font-size: xx-large;
}

.sidebar-header .p-3:not(.collapsed) .logo-text {
    opacity: 1;
    position: relative;
    /* Repositions the text next to the logo */
}

.sidebar:hover .main-logo-text {
    opacity: 1;
}

.sidebar-item:hover {
    /* background-color: #00000020; */
    /* background-color: rgba(255, 255, 255, 0.1); Very subtle hover effect */
    /* border: 2px solid transparent; */
}

.basic-header {
    /* position: fixed; */
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 1000;
    background: transparent;
    box-shadow: none;
    transition: left 0.3s ease, width 0.3s ease;
}

.header-content {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* padding: 0 20px; */
}

.header-right {
    display: flex;
    align-items: center;
    gap: 10px;
}

.header-right img {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 48px;
}

.profile-icon {
    padding-bottom: 4px;
    cursor: pointer;
    font-size: 35px !important;
    color: rgb(255, 255, 255);
    padding: 15%;
    background: #1f79ea;
    border-radius: 40px;
}

.dialog-container {
    border: none;
    border-radius: 8px;
    padding: 0;
    max-width: 500px;
    width: 90%;
    background: rgb(25 25 62);
    /* Dark blue background */
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.4);
    position: relative;
    outline: none;
}

.dialog-container2 {
    border: none;
    border-radius: 8px;
    padding: 0;
    max-width: 500px;
    width: 90%;
    background: rgb(25 25 62);
    /* Dark blue background */
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.4);
    outline: none;
}

.dialog-overlay {
    position: fixed;
    /* Makes the overlay cover the entire viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent dark background */
    z-index: 999;
    /* Ensure the overlay is above the background but below the dialog */
    display: flex;
    justify-content: center;
    align-items: center;
    /* Centers the dialog */
}

.dialog-content {
    padding: 24px;
    text-align: center;
    color: #ffffff;
}

.dialog-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #ffffff;
    /* White text for better contrast */
    margin-bottom: 12px;
}

.dialog-description {
    font-size: 1rem;
    color: #d4d4d4;
    /* Lighter gray text */
    margin-bottom: 20px !important;
}

.dialog-actions {
    display: flex;
    justify-content: space-between;
    gap: 12px;
}

.dialog-button {
    flex: 1;
    padding: 12px;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.dialog-cancel {
    background: darkgrey;
    /* Slightly lighter dark blue */
    color: white;
}

.dialog-cancel:hover {
    background: rgb(148, 143, 143);
    /* Darker dark blue on hover */
}

.dialog-confirm {
    background: red;
    /* Dark blue confirmation button */
    color: #ffffff;
    box-shadow: 0 2px 6px rgba(59, 130, 246, 0.4);
}

.dialog-confirm:hover {
    background: rgb(225, 8, 8);
    /* Slightly darker dark blue on hover */
}

.dialog-confirm2 {
    background: rgb(132, 240, 111);
    /* Dark blue confirmation button */
    color: #ffffff;
    box-shadow: 0 2px 6px rgba(59, 130, 246, 0.4);
}

.loader-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgb(0 0 0 / 69%);
    padding: 20px;
    border-radius: 8px;
    z-index: 9999;
    width: 100%;
    height: 100%;
    place-content: center;
    place-items: center;
}

.loader {
    width: 50px;
    height: 50px;
    border: 5px solid #fff;
    border-top: 5px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    place-self: center;
}

/* SearchBar */

.search_Container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: stretch;
    color: rgba(255, 255, 255, 0.7);
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: rgba(255, 255, 255, 0.70);
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.64px;
}

.search-bar {
    width: 100%;
    align-self: stretch;
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    position: relative;
}

.search-bar img {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}

.search-bar:hover {
    border: 1px solid #FFF;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.menu-item {
    margin-top: 10px;
    transition: background-color 0.1s ease-in-out;
}

.menu-item:hover {
    /* background-color: #374151; */
    /* Dark Gray */
    color: white;
}


/* InputBoxcss */


.custom_input-container {
    position: relative;
    width: 100%;
    display: flex;
    padding: 16px;
    align-items: center;
    /* gap: 10px; */
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.50);
}

.input-label {
    display: flex;
    padding: 2px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    left: 16px;
    top: -14px;
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.01);
    backdrop-filter: blur(8px);
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.24px;
}

.input-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    outline: none;
    border: none;
    background-color: inherit;
    color: #FFF;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
}