.profile_container {
    position: fixed;
    right: 34%;
    width: 433px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.16);
    backdrop-filter: blur(16px);;
    border: 1px solid rgba(175, 175, 175, 0.5);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 24px;
    text-align: left;
    height: 90vh;
    overflow-y: scroll;
    transition: "right 0.9s ease-in-out",
}
.profile_container::-webkit-scrollbar {
    display: none;
}

.profile_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.profile_img {
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    position: relative;
}

.profile_img img {
    display: flex;
    width: 160px;
    height: 160px;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;
    border-radius: 50%;
}

.profile_close {
    position: absolute;
    top: 0%;
    right: 0%;
    cursor: pointer;
}

.profile_close img {
    width: 24px;
    height: 24px;
}

.profile_data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.profile_data_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.profile_user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* gap: 4px; */
}

.profile_user_name {

    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 2.56px;
}

.profile_user_role {
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.28px;
}

.profile_Edit {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    border: 1px solid #FFF;
    cursor: pointer;
}

.profile_Edit img {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 4px;
    justify-content: center;
    align-items: center;
}

.profile_data_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.profile_data_connection {
    display: flex;
    align-items: flex-end;
    gap: 8px;

}

.profile_connection_count {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 1.92px;
}

.profile_text {
    /* width: 96px; */
    /* height: 28px; */
    /* -webkit-text-stroke-width: 0.2px; */
    -webkit-text-stroke-color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
}

.profile_text.connection{
    width: 96px;
    height: 28px;
    -webkit-text-stroke-width: 0.2px;
}

.profile_user_connections {
    display: flex;
    align-items: center;
}

.profile_user_connections img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: relative;
}

.connectedUser2 {
    width: 32px;
    position: relative;
    height: 32px;
    margin-left: -3px;
}

.connectedUser3 {
    width: 32px;
    position: relative;
    border-radius: 50%;
    height: 32px;
    object-fit: cover;
    margin-left: -3px;
}

.profile_data_collabaration {
    display: flex;
    align-items: center;
    gap: 16px;
}

.profile_data_pricing {
    display: flex;
    align-items: center;
    gap: 8px;
}

.profile_data_pricing img {
    width: 16px;
    height: 16px;
}

.profile_data_pricing span {
    /* -webkit-text-stroke-width: 0.2px; */
    -webkit-text-stroke-color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
}

.profile_content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.profile_content_title {
    align-self: stretch;
    -webkit-text-stroke-width: 0.4px;
    -webkit-text-stroke-color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 1.12px;
}

.profile_content_interest {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;

}

.profile_content_interest_item {
    display: flex;
    padding: 4px 16px;
    align-items: center;
    gap: 4px;
    border-radius: 80px;
    border: 1px solid #60C;
    background: rgba(102, 0, 204, 0.50);
}

.profile_content_socials{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.profile_content_socials img {
    display: flex;
    width: 45px;
    height: 45px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.profile-window {
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 4%;
    right: -100%;
    transition: right 0.8s ease-in-out;
    z-index: 1000;
}

.profile-window.show {
    /* position: absolute; */
    /* top: 5%; */
    right: 0;
    /* border:1px solid red */
    /* width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; */
}

.profile-overView {
    position: fixed;
    width: 100%;
    z-index: 1000;
    right: -100%;
    transition: right 0.5s ease-in-out;
}

.profile-overView.show {
    position: absolute;
    top: 12%;
    right: 0;
    width: 100%;
}

.profile-overView {
    position: fixed;
    top: 10px;
    backdrop-filter: blur(8px);
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.16);
    border: 1px solid rgba(175, 175, 175, 0.5);
    max-width: 180px;
    z-index: 1000; 
    display: none; 
}

.profile-overView.show {
    display: block;
    top: 90px;
    right: 40px;
}

.profile-overview_container {
    position: relative;
    border-radius: 16px;
    /* border: 1px solid rgba(175, 175, 175, 0.5); */
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 8px;
    gap: 8px;
    text-align: left;
    font-size: 16px;
}

.connections_title {
    position: relative;
    letter-spacing: 0.04em;
    font-weight: 200;
}
.connections_count{
    position: relative;
    font-size: 32px;
    letter-spacing: 0.08em;
    font-weight: 200;
    -webkit-text-stroke: 2px #fff;
}
.followers-count {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
}


.profile-overview-button{
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    padding: 8px 16px;
    gap: 8px;
    text-align: right;
    cursor: pointer;
}

.text {
    position: relative;
    letter-spacing: 0.04em;
    font-weight: bold;
}

.profile-overview-button:hover{
    background: rgba(41, 104, 254, 0.2);
    border-radius: 16px;
}

.profile-overview-button img {
    width: 22px;
    position: relative;
    height: 22px;
    overflow: hidden;
    flex-shrink: 0;
}

.section_line{
    display: flex;
    align-self: stretch;
    border-top: 1px solid rgba(175, 175, 175, 0.5);
}